<!-- 房型房间设置 -->
<template>
  <section class="cont houseType">
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>PMS</el-breadcrumb-item>
        <el-breadcrumb-item>住宿设置</el-breadcrumb-item>
        <el-breadcrumb-item>房型房间设置</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row class="content-box">
      <!-- 主体内容 -->
      <el-row class="table-box">
        <!-- 表格按钮 -->
        <div class="table-head-btn">
          <el-row class="right-btn">
            <el-button class="bg-gradient"  type="primary" @click="addEditEvnet('add')"><span>新增房型</span></el-button>
          </el-row>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border fit :stripe="true" v-loading="loading">
          <el-table-column prop="roomTypeName" label="房型名称" min-width="30"></el-table-column>
          <el-table-column prop="shortName" label="简称" min-width="30"></el-table-column>
          <el-table-column label="默认价" align="right" min-width="30">
            <template slot-scope="scope">
              <div v-if="scope.row.defaultPriceType === 'NO'">￥{{ scope.row.roomTypePrice}}</div>
              <div v-else>
                <div class="justify"><span>平日</span>  <span>￥{{ scope.row.weekdayPrice}}</span></div>
                <div class="justify"><span>周末</span>  <span>￥{{ scope.row.weekendPrice}}</span></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="roomTotalNum" label="房间数" align="right" min-width="30"></el-table-column>
          <el-table-column label="房间号" min-width="30">
            <template slot-scope="scope">
              {{ scope.row.roomNoArr.toString()}}
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="30">
            <template slot-scope="scope">
              <el-button type="text" @click="addEditEvnet('edit',scope.row)">
                <el-link type="primary">编辑</el-link>
              </el-button>
              <el-button type="text" @click="deleteBt(scope.row)">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      </el-row>
    </el-row>
    <!-- 新增/编辑弹窗 -->
    <el-dialog :title="`${addEdit}房型`" :visible.sync="show" width="800px" :before-close="handleClose" v-if="show">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" class="demo-ruleForm" label-position="right" label-width="120px">
        <el-form-item label="房型名称" prop="roomTypeName">
          <el-input class="width-300"  v-model="ruleForm.roomTypeName" placeholder="如：日式简约双人大床房" clearable></el-input>
        </el-form-item>
        <el-form-item label="简称" prop="shortName">
          <el-input class="width-300"  v-model="ruleForm.shortName" placeholder="如：大床房" clearable></el-input>
        </el-form-item>
        <el-form-item label="默认价">
          <el-radio-group v-model="ruleForm.defaultPriceType" @change="changeDefaultPriceType">
            <el-radio label="NO">不区分</el-radio>
            <el-radio label="YES">区分平日、周末</el-radio>
          </el-radio-group>
          <el-form-item  class="default" v-if="ruleForm.defaultPriceType === 'NO'" prop="roomTypePrice" key="NO">
            <span style="margin-right: 12px;">默认</span>
            <el-input class="width-220" v-model="ruleForm.roomTypePrice" :onkeyup='dataValidation' placeholder="默认价" clearable>
              <template slot="prepend">￥</template>
            </el-input>
          </el-form-item>
          <div v-else key="YES">
            <el-form-item class="default1" prop="weekdayPrice">
              <span style="margin-right: 12px;">平日</span>
              <el-input class="width-220"  v-model="ruleForm.weekdayPrice" :onkeyup='dataValidation' placeholder="周日至周四" clearable>
                <template slot="prepend">￥</template>
              </el-input>
            </el-form-item>
            <el-form-item class="default" prop="weekendPrice">
              <span style="margin-right: 12px;">周末</span>
              <el-input class="width-220" v-model="ruleForm.weekendPrice" :onkeyup='dataValidation' placeholder="周五、周六" clearable>
                <template slot="prepend">￥</template>
              </el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="房间数量" prop="roomTotalNum">
          <el-input-number class="width-150" v-model="ruleForm.roomTotalNum" @change="handleChange" :step="1" :min="0" :max="100"></el-input-number><span style="padding-left: 12px;">间</span>
        </el-form-item>
        <div class="roomNumber" v-show="ruleForm.roomTotalNum*1">房间号</div>
        <el-form-item class="houseNum" :class="index===0||index%6!==0? 'houseNum1':'houseNum2'" :prop="`rooms[${index}]`" :rules="rules.rooms" v-for="(item,index) in  ruleForm.roomTotalNum*1" :key="index">
          <el-input class="width-90" v-model="ruleForm.rooms[index]" :placeholder="`如:${101+index}`"></el-input>
        </el-form-item>
        <el-form-item label="房间图片" prop="">
          <el-row class="detail-image-box" type="flex">
            <div class="image-item" v-for="(item, index) in ruleForm.pmsRoomTypeImages" :key="index" @mouseenter="mask = 'block'" @mouseleave="mask = 'none'">
              <el-image :src="item.imageUrl"></el-image>
              <div class="mask" :style="{'display': mask}"><i @click="handleRemoveImage(item)" class="el-icon-delete"></i></div>
            </div>
            <div class="add-image" @click="visibleImage = true"><i class="el-icon-plus"></i></div>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button  @click="handleClose">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">完成</el-button>
      </span>
    </el-dialog>
    <Cropper :visible="visibleImage" @setImageInfo="getImageInfo" @closeUploadImg="closeUploadImg"></Cropper>
  </section>
</template>

<script>
import Cropper from '@/components/local/Cropper'
import { mapState } from "vuex";
import { pms } from '@/api/interface/pms';
import { isRepeat } from '@/common/js/common';
export default {
  components: {
    Cropper
  },
  data(){
    return {
      hotelId:'',              // 酒店ID
      id:'',                   // 编辑时主键ID
      tableData:[],            // 表格数据
      loading: true,           // 表格加载效果
      ruleForm:{               // 表单数据
        roomTypeName:'',       // 房型名称
        shortName:'',          // 简称
        defaultPriceType:'NO', // 默认价类型(NO:不区分,YES:区分平日周末)
        roomTypePrice:'',      // 默认价
        weekdayPrice:'',       // 平日价
        weekendPrice:'',       // 周末价
        roomTotalNum: 1,       // 房间总数
        rooms:[],              // 房间集合
        pmsRoomTypeImages: []
      },
      rules:{                  //校验规则
        roomTypeName:[{ required:true, message: '请输入房型名称' , trigger: 'blur'}],
        shortName: [{ required: true, message: '请输入简称', trigger: 'blur' }],
        roomTypePrice:[{ required:true,message: '请输入价格',trigger: 'blur'},],
        weekdayPrice:[{ required:true,message: '请输入价格',trigger: 'blur'},],
        weekendPrice:[{ required:true,message: '请输入价格',trigger: 'blur'},],
        roomTotalNum:[{ required:true, message: '请填写房间数量' , trigger: 'blur'}],
        rooms: [{ required:true, message: '请填写房间号' , trigger: 'blur' }]
      },

      num:0,                   // 房间数
      rooms:[],                // 房间信息

      show:false,              // 弹窗显示
      addEdit:'',              // 弹窗标题
      total: 0,                // 用户列表总条目数
      page: 1,                 // 当前页 默认第一页
      limit:10,                // 每页显示数
      visibleImage: false,
      mask: 'none',
      prompt: '提示！',
      del_success: "删除成功！",
      add_success: "添加成功！",
      update_success: '修改成功！',
      confirm_remove: '确定要删除吗？',
    }
  },
  computed: {
    ...mapState(['hotelInfo']),
    // 验证输入金额
    dataValidation(){
      return 'value=value.replace(/[^\\d.]/g,"").replace(".", "$#$").replace(/\\./g, "").replace("$#$", ".").replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, "$1$2.$3").replace(/^\\./g, "").replace(/\\.{2,}/g, ".")';
    },
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      if(oldVal && newVal.id !== oldVal.id){
        this.hotelId = this.hotelInfo.id;
        this.getData();
      }
    }
  },
  mounted(){
    this.hotelId = this.hotelInfo.id;
    this.getData();
  },
  methods:{
    // 获取房型列表
    getData(){
      const url = pms.getRoomTypePage + `?limit=${this.limit}&page=${this.page}`;
      const param = {
        hotelId:this.hotelId,                            // 酒店ID
        roomTypeSource:'PMS',
      }
      this.$axios.post(url, param, 'json').then((res) => {
        if (res.success) {
          this.total = res.total;
          this.tableData = res.records;
          this.loading = false;
          // 把请求来的房间号数组对象,改成房间号数组 存tableData对象roomNoArr中
          for (let index = 0; index < this.tableData.length; index++) {
            let arr = [];
            if (this.tableData[index].rooms) {
              this.tableData[index].rooms.forEach(element => {
                arr.push(element.roomNo);
              })
            }
            this.tableData[index].roomNoArr = arr;
          }
        }
      })
    },
    // 房间数量变化时触发
    handleChange(){
      let reg = /[^\d]/ ;
      if (this.ruleForm.rooms[this.ruleForm.roomTotalNum-2]!==undefined&&this.ruleForm.rooms[this.ruleForm.roomTotalNum-2]!=="") {
        this.ruleForm.rooms[this.ruleForm.roomTotalNum-1] = reg.test(this.ruleForm.rooms[this.ruleForm.roomTotalNum-2]) ? "" : this.ruleForm.rooms[this.ruleForm.roomTotalNum-2]*1+1+"";
      }
    },
    // 默认价类型变化时触发
    changeDefaultPriceType(){
      this.ruleForm.roomTypePrice = '';
      this.ruleForm.weekdayPrice = '';
      this.ruleForm.weekendPrice = '';
    },
    // 新增房价 / 编辑
    addEditEvnet(state ,item){
      this.show = true;
      if (state === "add") {
        this.addEdit = "新增";
      } else {
        console.log(item)
        this.addEdit = "编辑";
        this.id = item.id;
        this.ruleForm = {
          roomTypeName:item.roomTypeName,         // 房型名称
          shortName:item.shortName,               // 简称
          defaultPriceType:item.defaultPriceType, // 默认价类型(NO:不区分,YES:区分平日周末)
          roomTypePrice:item.roomTypePrice,       // 默认价
          weekdayPrice:item.weekdayPrice,         // 平日价
          weekendPrice:item.weekendPrice,         // 周末价
          roomTotalNum:item.roomTotalNum,         // 房间总数
          rooms:[...item.roomNoArr],              // 房间集合
          pmsRoomTypeImages: item.pmsRoomTypeImages.map(i => {
            return { id: i.id, imageUrl: i.imageUrl, ossImageUrl: i.ossImageUrl }
          })
        }
        this.num = item.roomTotalNum;
        this.rooms = item.rooms;
      }
    },
    // 移除详情图
    handleRemoveImage({ id, uid }) {
      this.ruleForm.pmsRoomTypeImages = this.ruleForm.pmsRoomTypeImages.filter(i => {
        return i.id !== id
      })
    },
    // 关闭上传图片会话框
    closeUploadImg() {
      this.visibleImage = false
    },
    // 获取上传图片信息
    getImageInfo({ url }, { uid }) {
      this.visibleImage = false
      this.ruleForm.pmsRoomTypeImages.push({ imageUrl: url, ossImageUrl: url, id: uid })
    },

    // 删除
    deleteBt({id}){
      this.$confirm(this.confirm_remove, this.prompt, {
        confirmButtonText: this.confirm,
        cancelButtonText: this.cancel,
        type: "warning",
      }).then(() => {
        const url = pms.delRoomType+ `${id}`;
        let params = {}
        this.$axios.get(url,params).then((res) => {
          if (res.success) {
            this.$message({showClose: true,message: this.del_success,type: 'success'});
            this.getData();
          }
        })
      });
    },
    // 改变每页数
    pageChange(num) {
      this.limit = num;
      this.getData();
    },
    // 改变当前页
    handlePaging(num) {
      this.page = num;
      this.getData();
    },
    // 重置表单数据
    reset(){
      this.ruleForm = {
        roomTypeName:'',       // 房型名称
        shortName:'',          // 简称
        defaultPriceType:'NO', // 默认价类型(NO:不区分,YES:区分平日周末)
        roomTypePrice:'',      // 默认价
        weekdayPrice:'',       // 平日价
        weekendPrice:'',       // 周末价
        roomTotalNum:1,        // 房间总数
        rooms:[],              // 房间集合
      }
    },
    // 关闭
    handleClose(){
      this.show =false;
      this.reset();
    },
    // 完成
    submitForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid){
          this.show =false;
          if ( !this.ruleForm.roomTotalNum || !isRepeat(this.ruleForm.rooms)) { // 判断房型下房间号是否重复
            if (this.addEdit === "新增") {
              let arr =[];
              if (this.ruleForm.roomTotalNum) {
                for (let index = 0; index < this.ruleForm.roomTotalNum; index++) {
                  let str = this.ruleForm.rooms[index]+"";
                  arr.push({"roomNo": str});
                }
              }
              const url = pms.addRoomType;
              const param = {
                hotelId:this.hotelId,                            // 酒店ID
                roomTypeName:this.ruleForm.roomTypeName,         // 房型名称
                shortName:this.ruleForm.shortName,               // 简称
                defaultPriceType:this.ruleForm.defaultPriceType, // 默认价类型(NO:不区分,YES:区分平日周末)
                roomTypePrice:this.ruleForm.roomTypePrice,       // 默认价
                weekdayPrice:this.ruleForm.weekdayPrice,         // 平日价
                weekendPrice:this.ruleForm.weekendPrice,         // 周末价
                roomTotalNum: this.ruleForm.roomTotalNum+"",     // 房间总数
                pmsRoomTypeImages: this.ruleForm.pmsRoomTypeImages,
                rooms: arr,                                      // 房间集合
                roomTypeSource:"PMS"                             // 房型来源\r\nPS：1：PMS（客控房型）：房型；2：ebooking
              }
              this.$axios.post(url, param, 'json').then((res) => {
                if (res.success) {
                  this.$message({showClose: true,message: this.add_success,type: 'success'});
                  this.reset();
                  this.getData();
                }
              })
            } else {
              let arr =[];
              if (this.ruleForm.roomTotalNum) {
                for (let index = 0; index < this.ruleForm.roomTotalNum; index++) {
                  let str = this.ruleForm.rooms[index]+"";
                  if ( this.num < index+1 ) {
                    arr.push({"roomNo": str});
                  }else{
                    let id = this.rooms[index].id;
                    arr.push({"roomNo": str,"id": id});
                  }
                }
              }
              let removeRoomIds = []; // 被删除的房间号ID
              if (this.ruleForm.roomTotalNum < this.num) {
                for (let index = this.ruleForm.roomTotalNum; index < this.num; index++) {
                  let str = this.rooms[index].id;
                  removeRoomIds.push(str);
                }
              }
              const url = pms.editRoomType;
              const param = {
                id:this.id,                                      // 主键ID
                hotelId:this.hotelId,                            // 酒店ID
                roomTypeName:this.ruleForm.roomTypeName,         // 房型名称
                shortName:this.ruleForm.shortName,               // 简称
                defaultPriceType:this.ruleForm.defaultPriceType, // 默认价类型(NO:不区分,YES:区分平日周末)
                roomTypePrice:this.ruleForm.roomTypePrice,       // 默认价
                weekdayPrice:this.ruleForm.weekdayPrice,         // 平日价
                weekendPrice:this.ruleForm.weekendPrice,         // 周末价
                roomTotalNum: this.ruleForm.roomTotalNum+"",     // 房间总数
                rooms: arr,                                      // 房间集合
                roomTypeSource:"PMS",                            // 房型来源\r\nPS：1：PMS（客控房型）：房型；2：ebooking
                removeRoomIds,                                   // 被删除的房间号ID
                pmsRoomTypeImages: this.ruleForm.pmsRoomTypeImages,
              }
              this.$axios.post(url, param, 'json').then((res) => {
                if (res.success) {
                  this.$message({showClose: true,message: this.update_success,type: 'success'});
                  this.reset();
                  this.getData();
                }
              })
            }
          }else{
            this.$message({showClose: true,message:"房间号重复",type: 'error'});
            this.reset();
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
}
</script>
<style lang="scss" scoped>
.houseType{
  .justify{
    display: flex;
    justify-content: space-between;
  }
  .el-form{
    max-height: 534px;
    overflow: auto;
    .el-form-item__content{
      line-height: 40px;
    }
    .default{
      background-color: rgb(245, 247, 250);
      padding: 20px;
      width: 260px;
      /deep/ .el-form-item__error{
        margin-left: 90px;
      }
    }
    .default1{
      background-color: rgb(245, 247, 250);
      padding: 20px 20px 2px 20px;
      width: 260px;
      /deep/ .el-form-item__error{
        margin-left: 90px;
      }
    }
    .roomNumber{
      width: 120px;
      line-height: 40px;
      padding-right: 12px;
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #606266;
      padding: 0 12px 0 0;
      box-sizing: border-box;
    }
    .houseNum{
      display: inline-block;
      .el-input{
        margin:0 10px 0px 0;
      }
    }
    .houseNum1{
      /deep/ .el-form-item__content{
        margin-left: 0 !important;
      }
    }
    .houseNum2{
      /deep/ .el-form-item__content{
        margin-left: 120px !important;
      }
    }
  }
  .demo-ruleForm{
    .detail-image-box{
      flex-wrap: wrap;
      .image-item {
        position: relative;
        .el-image { width: 120px; border: 1px #CCCCCC solid }
        .mask {
          position: absolute; width: 120px; height: 120px; left: 0; top: 0;
          text-align: center; font-size: 22px; background: rgba(0, 0, 0, 0.4);
          .el-icon-delete { color: #FFFFFF; line-height: 120px }
        }
      }
      .el-image{ width: 120px; height: 120px; margin: 0 10px 0 0; }
      .add-image {
        width: 120px; height: 120px; border: dashed 1px #c0ccda;
        border-radius: 6px; text-align: center; cursor: pointer;
        .el-icon-plus{ font-size: 22px; color: #8c939d; line-height: 120px }
      }
    }
  }
}
</style>
